// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SiteWideMessageQueryVariables = Types.Exact<{
  loggedIn: Types.Scalars['Boolean'];
  path: Types.Scalars['String'];
}>;


export type SiteWideMessageQuery = { __typename?: 'Query', siteWideMessages: Array<{ __typename?: 'SitewideMessage', text: string, id: string, priority: Types.Priority }> };


export const SiteWideMessageDocument = gql`
    query SiteWideMessage($loggedIn: Boolean!, $path: String!) {
  siteWideMessages(input: {loggedIn: $loggedIn, path: $path}) {
    text
    id
    priority
  }
}
    `;
export type SiteWideMessageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SiteWideMessageQuery, SiteWideMessageQueryVariables>, 'query'> & ({ variables: SiteWideMessageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SiteWideMessageComponent = (props: SiteWideMessageComponentProps) => (
      <ApolloReactComponents.Query<SiteWideMessageQuery, SiteWideMessageQueryVariables> query={SiteWideMessageDocument} {...props} />
    );
    

/**
 * __useSiteWideMessageQuery__
 *
 * To run a query within a React component, call `useSiteWideMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteWideMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteWideMessageQuery({
 *   variables: {
 *      loggedIn: // value for 'loggedIn'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useSiteWideMessageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SiteWideMessageQuery, SiteWideMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SiteWideMessageQuery, SiteWideMessageQueryVariables>(SiteWideMessageDocument, options);
      }
export function useSiteWideMessageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteWideMessageQuery, SiteWideMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SiteWideMessageQuery, SiteWideMessageQueryVariables>(SiteWideMessageDocument, options);
        }
export type SiteWideMessageQueryHookResult = ReturnType<typeof useSiteWideMessageQuery>;
export type SiteWideMessageLazyQueryHookResult = ReturnType<typeof useSiteWideMessageLazyQuery>;
export type SiteWideMessageQueryResult = ApolloReactCommon.QueryResult<SiteWideMessageQuery, SiteWideMessageQueryVariables>;