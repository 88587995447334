import { useState, useEffect, useCallback } from 'react'
import { createEventEmitter } from '@src/utils/createEventEmitter'

const emitter = createEventEmitter<{ change: PermissionState }>()

const permissionToStateMap: Record<NotificationPermission, PermissionState> = {
  default: 'prompt',
  granted: 'granted',
  denied: 'denied',
}

export const useNotifications = () => {
  const [permissionState, setPermissionState] = useState<
    PermissionState | undefined
  >(() => {
    return typeof window !== 'undefined' && 'Notification' in window
      ? permissionToStateMap[Notification.permission]
      : undefined
  })

  useEffect(() => emitter.on('change', setPermissionState), [])

  useEffect(() => {
    if (!navigator.permissions) {
      return
    }
    // `query` ensures we only deal with one instance of PermissionStatus.
    // Each permissions.query() call returns a new instance,
    // and each instance has its own event listeners.
    const query = navigator.permissions.query({ name: 'notifications' })
    const onChange = async () => {
      emitter.emit('change', (await query).state)
    }
    const loadAndTrackPermissionStatus = async () => {
      const permissionStatus = await query
      emitter.emit('change', permissionStatus.state)
      permissionStatus.addEventListener('change', onChange)
    }
    loadAndTrackPermissionStatus()
    return () => {
      query.then((s) => s.removeEventListener('change', onChange))
    }
  }, [])

  const requestNotificationsPermission = useCallback(async () => {
    const userResponse = await Notification.requestPermission()

    if (navigator.permissions) {
      // Chrome doesn't trigger permissionStatus.onchange if it was denied as
      // a result of the user dismissing the prompt several times in a row.
      const permission = await navigator.permissions.query({
        name: 'notifications',
      })
      emitter.emit('change', permission.state)
    } else {
      emitter.emit('change', permissionToStateMap[userResponse])
    }

    return userResponse
  }, [])

  return { permissionState, requestNotificationsPermission }
}
