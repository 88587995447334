import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useNotifications } from '@src/utils/useNotifications'
import { usePushMessages } from '@src/utils/usePushMessages'
import messages from '@src/utils/messages'
import {
  useGlobalSnackbar,
  SnackbarType,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'

import LinkStyleButton from '../LinkStyleButton'
import { AnySiteWideMessage, CtaType } from './types'

interface CtaProps {
  item: AnySiteWideMessage
  dismissMessage: (id: string) => void
  className?: string
}

const PushNotificationsCta: React.FC<CtaProps> = ({
  item,
  dismissMessage,
  className,
}) => {
  const [loading, setLoading] = useState(false)
  const { setGlobalSnackbarState } = useGlobalSnackbar()
  const { requestNotificationsPermission } = useNotifications()
  const { subscribe } = usePushMessages()
  const { formatMessage } = useIntl()
  const onClick = async () => {
    const userResponse = await requestNotificationsPermission()
    if (userResponse !== 'granted') {
      return
    }
    setLoading(true)
    await subscribe(userResponse)
    setLoading(false)
    setGlobalSnackbarState({
      open: true,
      message: formatMessage(messages.NOTIFICATIONS_ENABLED),
      variant: SnackbarType.Success,
    })
    dismissMessage(item.id)
  }

  return (
    <LinkStyleButton
      disableRipple
      className={className}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? (
        <>
          <FormattedMessage {...messages.TURNING_ON} />
          ...
        </>
      ) : (
        <FormattedMessage {...messages.TURN_ON} />
      )}
    </LinkStyleButton>
  )
}

export const SitewideMessageCta: React.FC<CtaProps> = (props) => {
  // eslint-disable-next-line default-case
  switch (props.item.ctaType) {
    case undefined:
      return null
    case CtaType.PushNotifications:
      return <PushNotificationsCta {...props} />
  }
}
