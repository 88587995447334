import { SiteWideMessageQuery } from '@src/queries/SiteWideMessagesQuery.generated'

export enum CtaType {
  PushNotifications = 'PushNotifications',
}
export type DynamicSiteWideMessage =
  SiteWideMessageQuery['siteWideMessages'][number] & {
    ctaType?: CtaType
  }
export type SystemMessage = Omit<DynamicSiteWideMessage, 'priority'> & {
  priority: 'System'
}
export type AnySiteWideMessage = DynamicSiteWideMessage | SystemMessage
