// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import { UserPushSubscriptionFragmentFragmentDoc } from '../fragments/UserPushSubscription.generated';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SaveUserPushSubscriptionMutationVariables = Types.Exact<{
  input: Types.PushSubscriptionInput;
}>;


export type SaveUserPushSubscriptionMutation = { __typename?: 'Mutation', saveUserPushSubscription: Array<{ __typename?: 'UserPushSubscription', id: string, deviceLabel: string, userId: string, subscription: { __typename?: 'PushSubscription', endpoint: string, keys: { __typename?: 'PushSubscriptionKeys', p256dh: string, auth: string } } }> };


export const SaveUserPushSubscriptionDocument = gql`
    mutation SaveUserPushSubscription($input: PushSubscriptionInput!) {
  saveUserPushSubscription(input: $input) {
    ...UserPushSubscriptionFragment
  }
}
    ${UserPushSubscriptionFragmentFragmentDoc}`;
export type SaveUserPushSubscriptionMutationFn = ApolloReactCommon.MutationFunction<SaveUserPushSubscriptionMutation, SaveUserPushSubscriptionMutationVariables>;
export type SaveUserPushSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveUserPushSubscriptionMutation, SaveUserPushSubscriptionMutationVariables>, 'mutation'>;

    export const SaveUserPushSubscriptionComponent = (props: SaveUserPushSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<SaveUserPushSubscriptionMutation, SaveUserPushSubscriptionMutationVariables> mutation={SaveUserPushSubscriptionDocument} {...props} />
    );
    

/**
 * __useSaveUserPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useSaveUserPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserPushSubscriptionMutation, { data, loading, error }] = useSaveUserPushSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserPushSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveUserPushSubscriptionMutation, SaveUserPushSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SaveUserPushSubscriptionMutation, SaveUserPushSubscriptionMutationVariables>(SaveUserPushSubscriptionDocument, options);
      }
export type SaveUserPushSubscriptionMutationHookResult = ReturnType<typeof useSaveUserPushSubscriptionMutation>;
export type SaveUserPushSubscriptionMutationResult = ApolloReactCommon.MutationResult<SaveUserPushSubscriptionMutation>;
export type SaveUserPushSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveUserPushSubscriptionMutation, SaveUserPushSubscriptionMutationVariables>;