import React, { useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import XIcon from '@icons/XIcon'
import { ButtonBase, Theme } from '@material-ui/core'
import { AppCookies, useCookies } from '@src/utils/cookies'
import { useCurrentUser } from '@utils/useCurrentUser'
import { useSiteWideMessageQuery } from '@src/queries/SiteWideMessagesQuery.generated'
import { HandleMarkup } from '@src/components/HandleMarkup'
import { useRouter } from '@src/routes'
import { parseRegionalUrl } from '@src/utils/regional'
import { useNotifications } from '@src/utils/useNotifications'
import { SitewideMessageCta } from './SitewideMessageCta'
import { AnySiteWideMessage, CtaType, SystemMessage } from './types'
import getConfig from 'next/config'
import { isPushSupported } from '@src/utils/usePushMessages'
import { useIntl } from 'react-intl'
import messages from '@src/utils/messages'

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    position: 'relative',
    // Custom focus state for visibility
    '& button': {
      '&:focus': { outline: 'revert' },
    },
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 20),
      maxWidth: `1440px`,
      marginLeft: `auto`,
      marginRight: `auto`,
    },
  },
  ctaContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  itemMessage: {
    display: 'flex',
    alignItems: 'baseline',
    '& > * + $ctaContainer': {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      borderLeft: `1px solid ${theme.palette.common.white}`,
    },
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.black,
    padding: theme.spacing(1),
    marginLeft: 'auto',
  },
  cta: {
    whiteSpace: 'nowrap',
    '&:disabled': {
      opacity: 0.5,
    },
  },
  critical: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'underline',
    },
  },
  informational: {
    background: theme.palette.warning.main,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  promotional: {
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  system: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '& $closeIcon': {
      color: theme.palette.common.white,
    },
    '& $cta': {
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.white,
        textDecoration: 'underline',
      },
    },
  },
}))

enum MessagePriorities {
  Critical = 'critical',
  Informational = 'informational',
  Promotional = 'promotional',
  System = 'system',
}

const SitewideMessages = () => {
  const { formatMessage } = useIntl()
  const { permissionState } = useNotifications()
  const classes = useStyles()
  const router = useRouter()
  const { userIsLoggedIn } = useCurrentUser()
  const [cookies, setCookies] = useCookies([AppCookies.HideSitewideMessages])

  const { data, loading, error } = useSiteWideMessageQuery({
    variables: {
      loggedIn: userIsLoggedIn,
      path:
        parseRegionalUrl(router.asPath)?.afterBasename?.split('?')[0] || '/',
    },
    ssr: false,
    fetchPolicy: 'network-only',
  })

  const dismissMessage = (id: string) => {
    const hiddenIds: string[] = cookies?.hideSitewideMessages?.split(',') || []
    setCookies(AppCookies.HideSitewideMessages, [...hiddenIds, id].join(','))
  }

  const siteMessages = useMemo((): AnySiteWideMessage[] | undefined => {
    if (!featureFlags.pushNotifications) {
      return data?.siteWideMessages
    }
    const systemMessages: SystemMessage[] = []
    if (
      userIsLoggedIn &&
      isPushSupported &&
      permissionState &&
      permissionState !== 'denied'
    ) {
      systemMessages.push({
        text: formatMessage(messages.TURN_ON_NOTIFICATIONS),
        ctaType: CtaType.PushNotifications,
        id: 'push-notification-prompt',
        priority: 'System',
      })
    }
    return [...(data?.siteWideMessages || []), ...systemMessages]
  }, [data, userIsLoggedIn, permissionState, formatMessage])

  if (error || loading || !siteMessages) return null

  const messagesToShow = siteMessages.filter(
    (item) => !cookies?.hideSitewideMessages?.split(',')?.includes(item.id)
  )

  return (
    <div data-testid="sitewide-messages">
      {messagesToShow.map((item) => (
        <div
          key={item.id}
          data-testid="sitewide-message"
          className={clsx(
            classes.itemWrapper,
            classes[MessagePriorities[item.priority]]
          )}
        >
          <div className={classes.item}>
            <div className={classes.itemMessage}>
              <HandleMarkup value={item.text} />
              <div className={classes.ctaContainer}>
                <SitewideMessageCta
                  className={classes.cta}
                  item={item}
                  dismissMessage={dismissMessage}
                />
              </div>
            </div>
            <ButtonBase
              aria-label="close"
              className={classes.closeIcon}
              onClick={() => dismissMessage(item.id)}
            >
              <XIcon fontSize="inherit" />
            </ButtonBase>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SitewideMessages
