import { useCallback, useState, useEffect } from 'react'
import { useSaveUserPushSubscriptionMutation } from '@src/mutations/SaveUserPushSubscription.generated'
import { PushSubscriptionInput } from '@src/types/graphql-types'
import getConfig from 'next/config'
import { createEventEmitter } from '@src/utils/createEventEmitter'

const {
  publicRuntimeConfig: { vapidKeys },
} = getConfig()

export const isPushSupported =
  typeof window !== 'undefined' && 'PushManager' in window

interface PushMessagesHook {
  // Firefox requires 'notifications' permission to subscribe to push messages.
  // Make sure you call Notification.requestPermission() before calling subscribe().
  subscribe: (
    userResponse: Extract<NotificationPermission, 'granted'>
  ) => Promise<void>
  subscription: PushSubscription | null
}

const emitter = createEventEmitter<{ change: PushSubscription | null }>()

export const usePushMessages = (): PushMessagesHook => {
  const [saveUserPushSubscription] = useSaveUserPushSubscriptionMutation({
    update: (cache, { data }) => {
      if (!data) return
      cache.writeData({
        data: {
          getUserPushSubscriptions: data.saveUserPushSubscription,
        },
      })
    },
  })

  const [subscription, setSubscription] = useState<PushSubscription | null>(
    null
  )
  useEffect(() => emitter.on('change', setSubscription), [])
  useEffect(() => {
    const getSubscription = async () => {
      const reg = await navigator.serviceWorker.ready
      emitter.emit('change', await reg.pushManager.getSubscription())
    }
    getSubscription()
  }, [])

  return {
    subscription,
    subscribe: useCallback(async () => {
      const swReg = await navigator.serviceWorker.ready
      if (!swReg.active) {
        return
      }

      const subscription = await swReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidKeys.publicKey,
      })
      emitter.emit('change', subscription)
      const subscriptionJson = subscription.toJSON() as PushSubscriptionInput

      await saveUserPushSubscription({ variables: { input: subscriptionJson } })
    }, [saveUserPushSubscription]),
  }
}
