// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
export type UserPushSubscriptionFragmentFragment = { __typename?: 'UserPushSubscription', id: string, deviceLabel: string, userId: string, subscription: { __typename?: 'PushSubscription', endpoint: string, keys: { __typename?: 'PushSubscriptionKeys', p256dh: string, auth: string } } };

export const UserPushSubscriptionFragmentFragmentDoc = gql`
    fragment UserPushSubscriptionFragment on UserPushSubscription {
  id
  deviceLabel
  userId
  subscription {
    endpoint
    keys {
      p256dh
      auth
    }
  }
}
    `;